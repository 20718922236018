import provideState_j4kZLnS1H1 from "/usr/local/app/node_modules/.pnpm/nuxt-graphql-middleware@4.1.1_graphql-tag@2.12.6_graphql@16.9.0_rollup@4.24.0_typescript@5.6.3_vite@5.4.8/node_modules/nuxt-graphql-middleware/dist/runtime/plugins/provideState.js";
import revive_payload_client_OLn8bWBqFI from "/usr/local/app/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_fpOKbjMaHc from "/usr/local/app/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GJAegbnbBG from "/usr/local/app/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MfU0D3dslB from "/usr/local/app/node_modules/.pnpm/nuxt-site-config@2.2.18_rollup@4.24.0_vite@5.4.8_vue@3.5.11/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_1Vx0BkHjzS from "/usr/local/app/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ORiEZgNOYK from "/usr/local/app/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Zvv9TfLcGo from "/usr/local/app/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Qztz8saqkR from "/usr/local/app/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/usr/local/app/.nuxt/components.plugin.mjs";
import prefetch_client_3QbxNbURPr from "/usr/local/app/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_rz3gPkYs9p from "/usr/local/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_rollup@4.24.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import documentDriven_0dRP2Cpop5 from "/usr/local/app/node_modules/.pnpm/@nuxt+content@2.13.2_nuxt@3.13.2_rollup@4.24.0_vue@3.5.11/node_modules/@nuxt/content/dist/runtime/legacy/plugins/documentDriven.js";
import nuxt_plugin_WoYiHY2EFc from "/usr/local/app/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_rollup@4.24.0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_kMwPeeWPbY from "/usr/local/app/node_modules/.pnpm/v-wave@3.0.2_rollup@4.24.0_vue@3.5.11/node_modules/v-wave/nuxt/runtime/plugin.ts";
import plugin_wy0B721ODc from "/usr/local/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import switch_locale_path_ssr_uQxXoWhtBq from "/usr/local/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.11/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_0T4Bi5RaJ6 from "/usr/local/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.11/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_gOrY24njur from "/usr/local/app/node_modules/.pnpm/@nuxt+image@1.8.1_rollup@4.24.0/node_modules/@nuxt/image/dist/runtime/plugin.js";
import unocss_MzCDxu9LMj from "/usr/local/app/.nuxt/unocss.mjs";
import plugin_HMUycbzStU from "/usr/local/app/modules/pino/runtime/plugin.ts";
import plugin_xDtleEcACi from "/usr/local/app/modules/gtm/runtime/plugin.ts";
import _0_graphqlConfig_yaUZblG674 from "/usr/local/app/plugins/0.graphqlConfig.ts";
import _1_auth_slaK5pfDbE from "/usr/local/app/plugins/1.auth.ts";
import _2_activeOrder_yYINElYs5H from "/usr/local/app/plugins/2.activeOrder.ts";
import collections_pRcEP3swaI from "/usr/local/app/plugins/collections.ts";
import foxentry_client_gcy3vwc779 from "/usr/local/app/plugins/foxentry.client.ts";
import scroll_client_HJUo2UKx9n from "/usr/local/app/plugins/scroll.client.ts";
import supportAgent_3y6YttNG9k from "/usr/local/app/plugins/supportAgent.ts";
import vee_validate_KcKlKmvCrJ from "/usr/local/app/plugins/vee-validate.ts";
export default [
  provideState_j4kZLnS1H1,
  revive_payload_client_OLn8bWBqFI,
  unhead_fpOKbjMaHc,
  router_GJAegbnbBG,
  _0_siteConfig_MfU0D3dslB,
  payload_client_1Vx0BkHjzS,
  navigation_repaint_client_ORiEZgNOYK,
  check_outdated_build_client_Zvv9TfLcGo,
  chunk_reload_client_Qztz8saqkR,
  components_plugin_KR1HBZs4kY,
  prefetch_client_3QbxNbURPr,
  plugin_client_rz3gPkYs9p,
  documentDriven_0dRP2Cpop5,
  nuxt_plugin_WoYiHY2EFc,
  plugin_kMwPeeWPbY,
  plugin_wy0B721ODc,
  switch_locale_path_ssr_uQxXoWhtBq,
  i18n_0T4Bi5RaJ6,
  plugin_gOrY24njur,
  unocss_MzCDxu9LMj,
  plugin_HMUycbzStU,
  plugin_xDtleEcACi,
  _0_graphqlConfig_yaUZblG674,
  _1_auth_slaK5pfDbE,
  _2_activeOrder_yYINElYs5H,
  collections_pRcEP3swaI,
  foxentry_client_gcy3vwc779,
  scroll_client_HJUo2UKx9n,
  supportAgent_3y6YttNG9k,
  vee_validate_KcKlKmvCrJ
]