import type { ComputedRef, Ref } from 'vue'
import type { CreateAddressInput, CreateCustomerInput, ErrorResultFragment, OrderDetailFragment, OrderDetailLineItemFragment, PaymentInput, ProductVariantFragment, ShippingMethodFragment } from '#graphql-operations'
import { shippingFormDataIsValid } from '~/graphql/utils/validation'
import type { AsyncDataExecuteOptions } from '#app/composables/asyncData'
import type { PaymentMethod } from '~/composables/paymentMethod'

interface CustomOrderStates {}
export type OrderState = 'Created' | 'Draft' | 'AddingItems' | 'ArrangingPayment' | 'PaymentAuthorized' | 'PaymentSettled' | 'PartiallyShipped' | 'Shipped' | 'PartiallyDelivered' | 'Delivered' | 'Modifying' | 'ArrangingAdditionalPayment' | 'Cancelled' | keyof CustomOrderStates

export default defineNuxtPlugin(async (nuxtApp) => {
  if (nuxtApp.payload.error)
    return {}

  const { $localePath } = useNuxtApp()
  const activeOrderError = useState<ErrorResultFragment>('activeOrderError')
  const { data: activeOrder, pending: activeOrderPending, refresh: refreshActiveOrder } = await useAsyncData(
    'activeOrder',
    async () => (await useGraphqlQuery('activeOrder')).data.activeOrder,
    {
      lazy: true,
      server: false,
    },
  )

  const hasActiveOrder = computed(() => activeOrder.value && activeOrder.value.active && activeOrder.value.lines.length > 0)
  const cartDrawerOpen = useState<boolean>('cartDrawerOpen')

  addRouteMiddleware('checkout', (to, from) => {
    // if its a stripe redirect, do not redirect away
    if (to.query.payment_intent && to.query.payment_intent_client_secret && to.query.redirect_status)
      return

    // if its a comgate redirect, do not redirect away
    if (to.query.comgateTransactionId && to.query.comgateRefId && to.query.comgatePaymentStatus) {
      return
    }

    if (to.meta.checkout && !hasActiveOrder.value) {
      if (import.meta.server && to.path === from.path)
        return navigateTo($localePath('/'))
      return navigateTo($localePath('/'))
    }
  }, { global: true })

  const currentRoute = useRoute()

  if (import.meta.client) {
    watch(hasActiveOrder, async (hasActiveOrder) => {
      if (currentRoute.meta.checkout && !hasActiveOrder)
        await navigateTo($localePath('/'))
    })
  }

  // Order State
  const transitionOrderToState = async (nextState: OrderState) => {
    if (activeOrder.value?.state === nextState || !hasActiveOrder.value)
      return

    activeOrderPending.value = true
    const nextOrderStates = (await useGraphqlQuery('nextOrderStates')).data.nextOrderStates
    activeOrderPending.value = false

    if (nextOrderStates.includes(nextState)) {
      const result = (await useGraphqlMutation('transitionOrderToState', { state: nextState })).data.transitionOrderToState

      if (isGraphqlError(result)) {
        activeOrderError.value = result
        throw new Error(result.message)
      }

      if (isGraphqlType(result, 'Order')) {
        activeOrder.value = result
        return
      }

      throw new Error('Failed to transition order to state')
    }
  }

  // Cart
  const addItemToOrder = async (productVariant: ProductVariantFragment, quantity = 1) => {
    if (productVariant && quantity) {
      activeOrderPending.value = true
      await transitionOrderToState('AddingItems')
      const result = (await useGraphqlMutation('addItemToOrder', { productVariantId: productVariant.id, quantity: +quantity })).data.addItemToOrder
      activeOrderPending.value = false

      if (isGraphqlError(result)) {
        activeOrderError.value = result
        throw new Error(result.message)
      }

      if (isGraphqlType(result, 'Order')) {
        activeOrder.value = result
        return
      }

      throw new Error('Failed to add item to order')
    }
  }

  const removeOrderLine = async (line: OrderDetailLineItemFragment) => {
    activeOrderPending.value = true
    await transitionOrderToState('AddingItems')
    const result = (await useGraphqlMutation('removeOrderLine', { orderLineId: line.id })).data.removeOrderLine
    activeOrderPending.value = false

    if (isGraphqlError(result)) {
      activeOrderError.value = result
      throw new Error(result.message)
    }

    if (isGraphqlType(result, 'Order')) {
      activeOrder.value = result
      return
    }

    throw new Error('Failed to remove item from order')
  }

  const adjustOrderLine = async (line: OrderDetailLineItemFragment, quantity: number) => {
    activeOrderPending.value = true
    await transitionOrderToState('AddingItems')
    const result = (await useGraphqlMutation('adjustOrderLine', { orderLineId: line.id, quantity })).data.adjustOrderLine
    activeOrderPending.value = false

    if (isGraphqlError(result)) {
      activeOrderError.value = result
      throw new Error(result.message)
    }

    if (isGraphqlType(result, 'Order')) {
      activeOrder.value = result
      return
    }

    throw new Error('Failed to adjust order line')
  }

  // Customer
  const setCustomerForOrder = async (customer: CreateCustomerInput) => {
    activeOrderPending.value = true
    await transitionOrderToState('AddingItems')
    const result = (await useGraphqlMutation('setCustomerForOrder', { input: customer })).data.setCustomerForOrder
    activeOrderPending.value = false

    if (isGraphqlError(result)) {
      activeOrderError.value = result
      throw new Error(result.message)
    }

    if (isGraphqlType(result, 'Order')) {
      activeOrder.value = result
      return
    }

    throw new Error('Failed to set customer for order')
  }

  // Shipping
  const setOrderShippingMethod = async (shippingMethod: Omit<ShippingMethodFragment, 'metadata' | 'price'>) => {
    activeOrderPending.value = true
    await transitionOrderToState('AddingItems')
    const result = (await useGraphqlMutation('setOrderShippingMethod', { shippingMethodId: shippingMethod.id })).data.setOrderShippingMethod
    activeOrderPending.value = false

    if (isGraphqlError(result)) {
      activeOrderError.value = result
      throw new Error(result.message)
    }

    if (isGraphqlType(result, 'Order')) {
      activeOrder.value = result
      return
    }

    throw new Error('Failed to set shipping method for order')
  }

  const setOrderShippingAddress = async (address: Partial<CreateAddressInput>, shouldValidate = true) => {
    const isComplete = shouldValidate ? shippingFormDataIsValid(address) : true

    if (isComplete) {
      activeOrderPending.value = true
      await transitionOrderToState('AddingItems')
      const result = (await useGraphqlMutation('setOrderShippingAddress', { input: assignBlankAddressFields(address) })).data.setOrderShippingAddress
      activeOrderPending.value = false

      if (isGraphqlError(result)) {
        activeOrderError.value = result
        throw new Error(result.message)
      }

      if (isGraphqlType(result, 'Order')) {
        activeOrder.value = result
        return
      }

      throw new Error('Failed to set shipping address for order')
    }
  }

  const setIntermediatePaymentMethod = async (paymentMethodCode: string) => {
    if (activeOrder.value?.customFields?.intermediatePaymentMethod === paymentMethodCode) {
      return
    }

    activeOrderPending.value = true
    const result = (await useGraphqlMutation('setIntermediatePaymentMethod', { paymentMethodCode })).data.setIntermediatePaymentMethod
    activeOrderPending.value = false

    if (isGraphqlError(result)) {
      activeOrderError.value = result
      throw new Error(result.message)
    }

    if (isGraphqlType(result, 'Order')) {
      activeOrder.value = result
      return
    }

    throw new Error('Failed to set intermediate payment method for order')
  }

  const applyCouponCode = async (couponCode: string) => {
    activeOrderPending.value = true
    await transitionOrderToState('AddingItems')
    const result = (await useGraphqlMutation('applyCouponCode', { couponCode })).data.applyCouponCode
    activeOrderPending.value = false

    if (isGraphqlError(result)) {
      activeOrderError.value = result
      throw new Error(result.message)
    }

    if (isGraphqlType(result, 'Order')) {
      activeOrder.value = result
      return
    }

    throw new Error('Failed to apply coupon code to order')
  }

  const addPaymentToOrder = async (input: PaymentInput) => {
    activeOrderPending.value = true
    await transitionOrderToState('ArrangingPayment')
    const result = (await useGraphqlMutation('addPaymentToOrder', { input })).data.addPaymentToOrder
    activeOrderPending.value = false

    if (isGraphqlError(result)) {
      activeOrderError.value = result
      throw new Error(result.message)
    }

    if (isGraphqlType(result, 'Order')) {
      // activeOrder.value = result
      return
    }

    throw new Error('Failed to add payment to order')
  }

  const fetchEligiblePaymentMethods = async () => {
    const { data: { eligiblePaymentMethods } } = await useGraphqlQuery('eligiblePaymentMethods')

    return eligiblePaymentMethods.filter(method => method.isEligible)?.map((method) => {
      let icon = 'heroicons:credit-card-solid'
      let logo, external

      if (method.code?.includes('bank')) {
        icon = 'heroicons:building-library-solid'
      }

      if (method.code?.includes('comgate')) {
        logo = 'payments/blik'
        external = true
      }

      const props = method.code?.includes('stripe') ? { stripe: 'card' } : {}

      return { ...method, icon, logo, external, ...props } as PaymentMethod
    })
  }

  const getSurchargesForOrderLine = (orderLineId: string) => {
    return activeOrder.value?.surcharges?.filter(surcharge => surcharge.orderLineId === orderLineId) || []
  }

  const orderSurcharges = computed(() => {
    return activeOrder.value?.surcharges?.filter(surcharge => surcharge.orderLineId == null) || []
  })

  const orderTotal = computed(() => {
    return activeOrder.value?.total || 0
  })

  return {
    provide: {
      activeOrder: {
        hasActiveOrder,
        cartDrawerOpen,
        activeOrder,
        activeOrderPending,
        transitionOrderToState,
        refreshActiveOrder,
        addItemToOrder,
        removeOrderLine,
        adjustOrderLine,
        setCustomerForOrder,
        setOrderShippingMethod,
        setOrderShippingAddress,
        setIntermediatePaymentMethod,
        addPaymentToOrder,
        applyCouponCode,
        fetchEligiblePaymentMethods,
        // getters
        getSurchargesForOrderLine,
        orderSurcharges,
        orderTotal,
      },
    },
  }
})

interface ActiveOrderPlugin {
  hasHasActiveOrder: ComputedRef<boolean>
  cartDrawerOpen: Ref<boolean>
  activeOrder: Ref<OrderDetailFragment>
  activeOrderPending: Ref<boolean>
  transitionOrderToState: (nextState: OrderState) => Promise<void>
  refreshActiveOrder: (opts?: AsyncDataExecuteOptions) => Promise<void>
  addItemToOrder: (productVariant: ProductVariantFragment, quantity?: number) => Promise<void>
  removeOrderLine: (line: OrderDetailLineItemFragment) => Promise<void>
  adjustOrderLine: (line: OrderDetailLineItemFragment, quantity: number) => Promise<void>
  setCustomerForOrder: (customer: CreateCustomerInput) => Promise<void>
  setOrderShippingMethod: (shippingMethod: Omit<ShippingMethodFragment, 'metadata' | 'price'>) => Promise<void>
  setOrderShippingAddress: (address: Partial<CreateAddressInput>, shouldValidate?: boolean) => Promise<void>
  setIntermediatePaymentMethod: (paymentMethodCode: string) => Promise<void>
  addPaymentToOrder: (input: PaymentInput) => Promise<void>
  applyCouponCode: (couponCode: string) => Promise<void>
  fetchEligiblePaymentMethods: () => Promise<PaymentMethod[]>
  getSurchargesForOrderLine: (orderLineId: string) => OrderDetailFragment['surcharges']
  orderSurcharges: ComputedRef<OrderDetailFragment['surcharges']>
  orderTotal: ComputedRef<number>
}

declare module '#app' {
  interface NuxtApp {
    $activeOrder: ActiveOrderPlugin
  }
  interface PageMeta {
    checkout?: boolean
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $activeOrder: ActiveOrderPlugin
  }
}
